import React from "react"
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import SplashImage from "../components/SplashImage";
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <div style={{ marginBottom: `1.45rem`}}>
      <SplashImage />
    </div>
  {/* <Link to="/page-2/">Go to page 2</Link> */ }
  </Layout>
)

export default IndexPage;

