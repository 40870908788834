/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql, Link } from "gatsby";

import Header from "./header"
import "./layout.css"

const ListLink = props => (
  <li
    style={{
      display: 'block',
      marginRight: '1rem',
    }}
  >
    <Link to={props.to} style={{
      color: 'black',
      textDecoration: 'none',
      fontSize: '19px',
      fontWeight: 'bold'
    }}>
      {props.children}
    </Link>
  </li>
);

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query TitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <div className='left-nav' style={{}}>
          <Header className='logo' siteTitle={data.site.siteMetadata.title} />
          <ul style={{ marginLeft: '20px' }}>
            {/* <ListLink to="/gallery">Gallery</ListLink> */ }
            <ListLink to="/contact">Contact</ListLink>
          </ul>
        </div>
        <div>
          <div 
            style={{
              margin: `0 auto`,
              maxWidth: 960,
              padding: `0px 1.0875rem 1.45rem`,
              paddingTop: 0,
            }}
          >
            <main>{children}</main>
          </div>
        </div>
      </>
    )}
  />
)
