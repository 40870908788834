import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

const Header = ({ siteTitle }) => (
    <div
      style={{
        marginTop: '20px',
        marginLeft: '20px',
        marginBottom: '60px'
      }}
      className='logo'
    >
      <Link
        to="/"
        style={{
          color: 'black',
          textDecoration: `none`,
          fontSize: '19px',
          fontWeight: 'bold',
        }}
      >
        {siteTitle.split(" ").map(titleWord => (
          <div>
            {titleWord}
          </div>
        ))}
      </Link>
    </div>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
